import {
  getInMemoryCalledMethods,
  registerPublicMethods,
  registerPublicMethodsTracking,
} from '../entry/iAdvizeInterface/iAdvizeInterface';
import { launchDataPipeline } from './dataPipeline';
import { registerSetVisitorCookiesConsent } from './cookieConsent';
import { registerNavigation } from './targeting/navigation';
import {
  getStoredVisitorIdentity,
  setInMemoryVisitorIdentity,
  setStoredVisitorIdentity,
} from '../entry/storedVisitorIdentity';
import {
  CommonConfig,
  HTMLCustomDataConfig,
} from '../entry/visitorContextConfig/types';

import { registerSetVisitorGdprConsent } from './gdpr';
import registerRecordTransaction from './transaction/registerRecordTransaction';
import { getUseExplicitCookiesConsent } from '../entry/explicitCookiesConsent/explicitCookiesConsent';
import { getStorageType } from '../entry/storage';
import triggerTargeting from './targeting/trigger';
import { getPublicPropertiesStore } from '../entry/publicMethods/publicProperties/registerPublicProperties';
import { CrossEventEmitterType } from '../entry/crossEventEmitter';
import startJSCustomDataObserver from './targeting/customData/JSCustomData/JSCustomDataObserver';
import { LaunchFullModeParams } from '../entry/types';
import { getStaticConfig } from '../entry/visitorContextConfig/getStaticConfig';
import registerAuthentication from '../entry/authentication/registerAuthentication';
import debugLauncher from '../entry/publicMethods/debug/debugLauncher';
import { setTemplateName } from '../entry/visitorContextConfig/websiteConfiguration';
import trackEngagementRules from './trackEngagementRules';

export default async (
  vuid: string,
  sid: number,
  htmlCustomDataConfig: HTMLCustomDataConfig,
  isDeviceIdEnabled: boolean,
  crossEventEmitter: CrossEventEmitterType,
  launchFullMode: (params: LaunchFullModeParams) => void,
  commonConfig: CommonConfig,
  delayBeforeNextCall?: number,
  initialLocation: string = window.location.href,
  initialJSCustomData: Record<string, string | number | boolean> = {},
  isAuthenticationEnabled: boolean = false,
) => {
  const publicPropertiesStore = getPublicPropertiesStore();
  publicPropertiesStore.dispatch('tag:version', 'LIGHT');

  const { deviceId } = getStoredVisitorIdentity(sid);
  const { sendDataEvent } = launchDataPipeline(
    vuid,
    sid,
    deviceId,
    commonConfig,
  );

  const { navigate, setPrevPageCustomDataFn } = registerNavigation({
    sid,
    initialLocation,
    isAuthenticationEnabled,
    commonConfig,
  });
  registerSetVisitorGdprConsent(sid, vuid, sendDataEvent, commonConfig);
  const recordTransaction = registerRecordTransaction(
    vuid,
    sid,
    deviceId,
    publicPropertiesStore,
    commonConfig,
  );
  setInMemoryVisitorIdentity({ vuid, deviceId });
  registerSetVisitorCookiesConsent(sid, sendDataEvent, ({ ttl }) => {
    setStoredVisitorIdentity(
      sid,
      vuid,
      isDeviceIdEnabled ? deviceId : undefined,
      ttl,
    );
  });

  registerPublicMethodsTracking((method) => {
    sendDataEvent({
      event: 'engagement.sdk.executed',
      properties: {
        type: 'webSDK',
        method,
      },
    });
  });

  //______________________________________________________________________
  publicPropertiesStore.on('visitor:cookiesConsent', (consent) => {
    if (consent) {
      getInMemoryCalledMethods().sdk.forEach((method) => {
        sendDataEvent({
          event: 'engagement.sdk.executed',
          properties: {
            type: 'webSDK',
            method,
          },
        });
      });
    }
  });

  if (commonConfig.debugModeAvailable) {
    debugLauncher(
      commonConfig.launchdarklyClientKey,
      commonConfig.graphql.url,
      commonConfig.engagement.url,
      commonConfig.language,
    );
  }

  if (isAuthenticationEnabled) {
    await registerAuthentication(
      sid,
      vuid,
      commonConfig.graphql.url,
      commonConfig,
    );
  }

  registerPublicMethods({
    navigate,
    recordTransaction,
  });

  sendDataEvent({
    event: 'engagement.tag.loaded',
    properties: {
      storageType: getStorageType(),
      referrer: document.referrer,
      tagVersion: 'LIGHT',
      url: document.location.href,
      useExplicitCookiesConsent: getUseExplicitCookiesConsent(),
      buildVersion: process.env.BUILD_IDENTIFIER!,
    },
  });
  if (Number.isFinite(delayBeforeNextCall)) {
    setTimeout(
      () =>
        triggerTargeting({
          registerNavigation: false,
          sid,
          isAuthenticationEnabled,
          commonConfig,
        }),
      delayBeforeNextCall,
    );
  }

  startJSCustomDataObserver(
    sid,
    triggerTargeting,
    publicPropertiesStore,
    isAuthenticationEnabled,
    commonConfig,
    initialJSCustomData,
  );

  if (Array.isArray(htmlCustomDataConfig) && htmlCustomDataConfig.length > 0) {
    await import(
      /*________________________________________________*/ './targeting/customData/HTMLCustomData/HTMLCustomData'
    ).then(({ registerCustomData }) =>
      registerCustomData({
        htmlCustomDataConfig,
        sid,
        setPrevPageCustomDataFn,
        triggerTargeting,
        isAuthenticationEnabled,
        commonConfig,
      }),
    );
  }
  crossEventEmitter.on('conversation-started', (uuid) => {
    //_____________________________________
    if (crossEventEmitter.isLocalEmitter(uuid)) {
      return;
    }
    //________________________________________
    getStaticConfig(sid, commonConfig.language).then(
      ({ config: staticConfig }) => {
        setTemplateName(staticConfig.template);
        launchFullMode({ staticConfig, vuid, commonConfig });
      },
    );
  });

  if (commonConfig.computeAvailabilitiesEnabled) {
    trackEngagementRules(sendDataEvent);
  }
};
