import triggerTargeting from './trigger';
import { activeTimeSpentOnPageTimer } from '../../entry/visitorState/visitorActivityOnPage';
import { browsingTimeSpentOnPageTimer } from '../../entry/visitorState/browsingTimeSpentOnPageTimer';
import {
  CommonConfig,
  CustomDataValues,
} from '../../entry/visitorContextConfig/types';
import createState from '../../shared/utils/createState';
import throttle from '../../shared/utils/throttle';

const URL_OBSERVER_INTERVAL = 2000;
const NAVIGATE_THROTTLING_DELAY = 1000;

let currentLocation = window.location.href;
let previousLocation = document.referrer;

export const registerNavigation = ({
  sid,
  initialLocation,
  isAuthenticationEnabled,
  commonConfig,
}: {
  sid: number;
  initialLocation: string;
  isAuthenticationEnabled: boolean;
  commonConfig: CommonConfig;
}) => {
  const [getPrevPageCustomDataFn, setPrevPageCustomDataFn] = createState<
    () => CustomDataValues
  >(() => ({}));

  let urlObserverInterval: number;
  const navigate = throttle(async (location: string) => {
    previousLocation = currentLocation;
    currentLocation = location;
    activeTimeSpentOnPageTimer.reset();
    browsingTimeSpentOnPageTimer.reset();
    browsingTimeSpentOnPageTimer.start();
    const { hasLaunchedFullMode } = await triggerTargeting({
      location,
      previousLocation,
      registerNavigation: true,
      sid,
      customData: getPrevPageCustomDataFn()(),
      isAuthenticationEnabled,
      commonConfig,
    });

    if (hasLaunchedFullMode) {
      clearInterval(urlObserverInterval);
    }
  }, NAVIGATE_THROTTLING_DELAY);

  //___________________________________________________
  //__________________________________________
  let currentPage = initialLocation;
  urlObserverInterval = window.setInterval(() => {
    const newPage = window.location.href;
    if (currentPage !== newPage) {
      navigate(newPage);
      currentPage = newPage;
    }
  }, URL_OBSERVER_INTERVAL);

  return { navigate, setPrevPageCustomDataFn };
};
