import { getInMemoryJwtCookie } from '../../entry/authentication/inMemoryJwtCookie';
import { launchFullMode } from '../../entry/launchFullMode';
import { StaticConfig } from '../../entry/types';
import { getStaticConfig } from '../../entry/visitorContextConfig/getStaticConfig';
import {
  abortVisitorContextCall,
  getVisitorContextConfig,
} from '../../entry/visitorContextConfig/getVisitorContextConfig';
import { CommonConfig } from '../../entry/visitorContextConfig/types';
import { setTemplateName } from '../../entry/visitorContextConfig/websiteConfiguration';
import { getIsAuthorized } from '../../entry/visitorState/authorizedState';
import { browsingTimeSpentOnPageTimer } from '../../entry/visitorState/browsingTimeSpentOnPageTimer';
import { activeTimeSpentOnPageTimer } from '../../entry/visitorState/visitorActivityOnPage';
import createState from '../../shared/utils/createState';
import { TargetingParams, TriggerTargetingFn } from '../types';
import { setExecutedEngagementRules } from '../../entry/visitorState/executedEngagementRules';
import { setExecutedScoringRuleIds } from '../../entry/visitorState/executedScoringRuleIds';
import CustomEventEmitter from '../../shared/utils/CustomEventEmitter';
import { AllEvents } from '../../live/src/event-manager/types';
import { setIsFromLightMode } from '../../entry/visitorContextConfig/executionContext';

const inMemoryParams: TargetingParams = {
  location: window.location.href,
  previousLocation: document.referrer,
};

const [getIsFullMode, setIsFullMode] = createState(false);

const fallbackInMemoryParam = <Param extends keyof TargetingParams>(
  key: Param,
  value: TargetingParams[Param],
): Required<TargetingParams>[Param] =>
  (value as Required<TargetingParams>[Param]) ?? inMemoryParams[key];

const storeInMemoryParams = (params: TargetingParams): void => {
  (Object.keys(params) as (keyof TargetingParams)[]).forEach((key) => {
    const value = params[key];
    if (value) {
      (inMemoryParams[key] as typeof value) = value;
    }
  });
};

let pendingTargetingTimeoutId: number | null = null;

const triggerTargeting: TriggerTargetingFn = async ({
  customData,
  location,
  previousLocation,
  registerNavigation,
  sid,
  warnings = [],
  isAuthenticationEnabled = false,
  commonConfig,
}: TargetingParams & {
  registerNavigation: boolean;
  sid: number;
  warnings?: string[];
  isAuthenticationEnabled?: boolean;
  commonConfig: CommonConfig;
}): Promise<{
  hasLaunchedFullMode: boolean;
}> => {
  if (getIsFullMode()) {
    return { hasLaunchedFullMode: true };
  }
  if (pendingTargetingTimeoutId) {
    //_____________________________________________________________________________________
    //_______________________________________________
    clearTimeout(pendingTargetingTimeoutId);
    pendingTargetingTimeoutId = null;
  }

  try {
    const visitorContextConfig = await getVisitorContextConfig({
      activeTimeSpentOnPage: activeTimeSpentOnPageTimer.time,
      browsingTimeSpentOnPage: browsingTimeSpentOnPageTimer.time,
      initialRequest: false,
      isAuthenticationEnabled,
      location: fallbackInMemoryParam('location', location),
      previousLocation: fallbackInMemoryParam(
        'previousLocation',
        previousLocation,
      ),
      customData,
      registerNavigation,
      sid,
      warnings,
    });

    CustomEventEmitter<AllEvents>('dispatcher').emit('targeting.triggered', {
      currentLocation: location,
      previousLocation,
    });

    setExecutedScoringRuleIds(visitorContextConfig.executedRuleIds || []);
    setExecutedEngagementRules(
      visitorContextConfig.matchedEngagementRules || [],
    );
    if (
      visitorContextConfig.tagVersion === 'FULL' &&
      getIsAuthorized() &&
      !abortVisitorContextCall.signal.aborted
    ) {
      //______________________________________________________________________________________________________________________________
      abortVisitorContextCall.abort();
      setIsFullMode(true);

      const staticConfig: StaticConfig = await getStaticConfig(
        sid,
        commonConfig.language,
      );
      setTemplateName(staticConfig.config.template);

      setIsFromLightMode(true);

      launchFullMode({
        staticConfig: staticConfig.config,
        vuid: isAuthenticationEnabled
          ? getInMemoryJwtCookie()?.vuid || visitorContextConfig.vuid
          : visitorContextConfig.vuid,
        screen: fallbackInMemoryParam('location', location),
        previousScreen: fallbackInMemoryParam(
          'previousLocation',
          previousLocation,
        ),
        commonConfig,
      });
      return { hasLaunchedFullMode: true };
    }
    storeInMemoryParams({ location, previousLocation });

    if (Number.isFinite(visitorContextConfig.delayBeforeNextCall)) {
      pendingTargetingTimeoutId = window.setTimeout(
        () =>
          triggerTargeting({
            registerNavigation: false,
            sid,
            isAuthenticationEnabled,
            commonConfig,
          }),
        visitorContextConfig.delayBeforeNextCall,
      );
    }
    //______________________________________
  } catch (_e) {}

  return { hasLaunchedFullMode: false };
};

export default triggerTargeting;
