import { getUseExplicitCookiesConsent } from '../entry/explicitCookiesConsent/explicitCookiesConsent';
import getTopDomain from '../storage/cookieStorage/getTopDomain';
import { registerPublicMethods } from '../entry/iAdvizeInterface/iAdvizeInterface';
import { DataEventWithProperties, SendDataEventFn } from './types';
import noop from '../shared/utils/noop';
import { getCookie } from '../storage/cookieStorage/getCookie';
import get from '../entry/publicMethods/get/get';
import { registerSetter } from '../entry/publicMethods/set/set';
import debounce from '../shared/utils/debounce';
import deprecate from '../shared/utils/deprecate';
import { DEFAULT_TTL_S } from '../storage/cookieStorage/constants';
import { setCookie } from '../storage/cookieStorage/setCookie';
import { delCookie } from '../storage/cookieStorage/delCookie';
import { ExpressedConsent, StringOrBoolConsent } from '../shared/types/consent';
import { getExpressedConsentFromStringOrBoolean } from '../shared/consentHelpers';
import { getPublicPropertiesStore } from '../entry/publicMethods/publicProperties/registerPublicProperties';

export const getCookieConsent = (): ExpressedConsent =>
  get('visitor:cookiesConsent') as ExpressedConsent;

const getCookieKey = (sid: number) => `iadvize-${sid}-consent`;

export const getStoredCookieConsent = (sid: number): StringOrBoolConsent =>
  getCookie(getCookieKey(sid)) as StringOrBoolConsent;

export const registerSetVisitorCookiesConsent = (
  sid: number,
  sendDataEvent: SendDataEventFn,
  cb: (options: { ttl: number }) => void,
  debounceTimeout = 500,
  useExplicitCookiesConsent = getUseExplicitCookiesConsent(),
) => {
  const cookieKey = getCookieKey(sid);
  const setVisitorCookiesConsent = (
    stringOrBoolConsent: StringOrBoolConsent,
    ttl: number = DEFAULT_TTL_S,
    writeCookie: boolean = true,
  ) => {
    if (!useExplicitCookiesConsent) {
      if (writeCookie) {
        cb({ ttl });
      }
      return;
    }

    const expressedConsent =
      getExpressedConsentFromStringOrBoolean(stringOrBoolConsent);
    if (expressedConsent === null || expressedConsent === getCookieConsent()) {
      return;
    }

    const domain = getTopDomain();

    if (writeCookie) {
      setCookie({
        domain,
        name: cookieKey,
        value: String(expressedConsent),
        ttl,
      });
    }

    getPublicPropertiesStore().dispatch(
      'visitor:cookiesConsent',
      expressedConsent,
    );

    if (expressedConsent === true) {
      if (writeCookie) {
        cb({ ttl });
      }
    } else {
      delCookie(`iadvize-${sid}-vuid`, domain);
    }
  };
  if (useExplicitCookiesConsent !== true) {
    setVisitorCookiesConsent(true);
    registerPublicMethods({ setVisitorCookiesConsent: noop });
    return;
  }

  const existingCookieConsent = getStoredCookieConsent(sid);
  setVisitorCookiesConsent(existingCookieConsent, DEFAULT_TTL_S, false);

  const setter = (expressedConsent: ExpressedConsent, ttl?: number) => {
    if (expressedConsent === null) {
      return;
    }
    setVisitorCookiesConsent(expressedConsent, ttl);
    sendDataEvent({
      event:
        getExpressedConsentFromStringOrBoolean(expressedConsent) === true
          ? 'engagement.visitor.cookie.cookieConsentAccepted'
          : 'engagement.visitor.cookie.cookieConsentRefused',
      properties: { consentSource: 'WebSDK', ttl },
    } as DataEventWithProperties);
  };

  registerSetter('visitor:cookiesConsent', debounce(setter, debounceTimeout));

  registerPublicMethods({
    setVisitorCookiesConsent: debounce(
      (hasConsented: boolean, ttl?: number, callback?: Function) => {
        deprecate({
          deprecated: 'iAdvize.setVisitorCookiesConsent(consent, ttl)',
          alternative: "iAdvize.set('visitor:cookiesConsent', consent, ttl)",
        });
        setter(hasConsented, ttl);
        callback?.();
      },
      500,
    ),
  });
};
