/*_______________________________*/
export default function deprecate({
  deprecated,
  alternative,
}: {
  deprecated: string;
  alternative: string;
}) {
  console.warn(`[iAdvize deprecation warning]
"${deprecated}" is deprecated.
${alternative ? `Please use "${alternative}" instead` : ''}
`);
}
