import { getPlatform } from '../../entry/buildUrl/getPlatformDomain';
import { getPublicPropertiesStore } from '../../entry/publicMethods/publicProperties/registerPublicProperties';
import { CommonConfig } from '../../entry/visitorContextConfig/types';
import { PublicPropertiesStore } from '../../shared/types/publicMethods';
import noop from '../../shared/utils/noop';
import retrier from '../../shared/utils/retrier';
import storage from '../../entry/storage';

type Transaction = {
  amount: string | number;
  id: string;
};

type LegacyTransaction = typeof window.idzTrans;

type TransactionPayload = {
  amount: number;
  deviceId: string;
  eventName: 'engagement.transaction.concluded';
  externalConversionId: string;
  platformId: string;
  socialUtmCampaign: string | null;
  source: 'ONSITE';
  sourceVisitorId: string;
  visitorDate: string;
  websiteId: number;
};

type RecordTransactionFnWithCalls = {
  (transaction?: Transaction | LegacyTransaction): void;
  calls: {
    id: string;
    amount: number;
  }[];
};

const TRANSACTION_COOKIES_TTL = 604900; //______________________
const UTM_CAMPAIGN_STORAGE_KEY = 'idzUtmCampaign';
const RETRIES = 15;

const getIsLegacyTransaction = (
  transaction?: Transaction | LegacyTransaction,
): transaction is LegacyTransaction =>
  (transaction as LegacyTransaction)?.cartAmount !== undefined;

function registerRecordTransaction(
  vuid: string,
  sid: number,
  deviceId: string,
  publicPropertiesStore: PublicPropertiesStore,
  commonConfig: CommonConfig,
): RecordTransactionFnWithCalls {
  const storageKey = `iadvize-${sid}-${UTM_CAMPAIGN_STORAGE_KEY}`;
  const getIdzTrans = (legacyTransaction?: LegacyTransaction): Transaction => ({
    amount: legacyTransaction?.cartAmount ?? window.idzTrans?.cartAmount,
    id: legacyTransaction?.tID ?? window.idzTrans?.tID ?? String(undefined),
  });

  const getSocialCampaign = (): string | null => {
    const { searchParams } = new URL(window.location.href);
    const campaign = searchParams.get('utm_campaign');
    const roi = searchParams.get('utm_roi') as string;
    if (['idz', 'iAdvize'].includes(roi) && campaign !== null) {
      storage.set(storageKey, campaign, {
        ttl: TRANSACTION_COOKIES_TTL,
      });
      return campaign;
    }
    return storage.get(storageKey);
  };

  //______________________________________________________
  getPublicPropertiesStore().on('visitor:cookiesConsent', () => {
    getSocialCampaign();
  });

  const recordTransaction = <RecordTransactionFnWithCalls>(
    function (transaction?: Transaction | LegacyTransaction) {
      const socialUtmCampaign = getSocialCampaign();

      const { amount, id } = getIsLegacyTransaction(transaction)
        ? getIdzTrans(transaction)
        : transaction || getIdzTrans();

      [{ amount }, { id }].forEach((obj) => {
        const key = Object.keys(obj)[0] as keyof Transaction;
        if (obj[key] === undefined) {
          throw new Error(`The "${key}" field of the transaction is missing`);
        }
      });

      const {
        engagement: { url: engagementUrl },
      } = commonConfig;
      const platformId = getPlatform();
      const transactionPayload: TransactionPayload = {
        amount: typeof amount === 'number' ? amount : parseFloat(amount),
        deviceId,
        eventName: 'engagement.transaction.concluded',
        externalConversionId: String(id),
        platformId,
        socialUtmCampaign,
        source: 'ONSITE',
        sourceVisitorId: vuid,
        visitorDate: new Date().toISOString(),
        websiteId: Number(sid),
      };

      //____________________________________________________
      retrier(
        () =>
          fetch(`${engagementUrl}/conversions?platform=${platformId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(transactionPayload),
          }).then((res) => {
            if (res?.status >= 500) {
              throw new Error();
            }

            if (transaction) {
              recordTransaction.calls.push({
                id,
                amount: transactionPayload.amount,
              });
            }
          }),
        RETRIES,
        (retriesLeft) => 1000 * (RETRIES + 1 - retriesLeft),
      ).catch(noop);
    }
  );
  recordTransaction.calls = [];

  let legacyTransactionsObserverTimeout: number | null = null;
  function observeLegacyTransactions() {
    //______________________________________________________________
    if (window.idzTrans) {
      recordTransaction();
    } else {
      //___________________________________________________________
      legacyTransactionsObserverTimeout = window.setTimeout(
        observeLegacyTransactions,
        500,
      );
    }
  }
  observeLegacyTransactions();
  publicPropertiesStore.on('tag:version', (tagVersion) => {
    if (tagVersion === 'FULL') {
      clearTimeout(legacyTransactionsObserverTimeout!);
    }
  });

  return recordTransaction;
}

export default registerRecordTransaction;
