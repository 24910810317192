import debounce from '../shared/utils/debounce';
import { SendDataEventFn } from './types';
import { registerSetter } from '../entry/publicMethods/set/set';
import { ExpressedConsent, StringOrBoolConsent } from '../shared/types/consent';
import { getExpressedConsentFromStringOrBoolean } from '../shared/consentHelpers';
import { getPublicPropertiesStore } from '../entry/publicMethods/publicProperties/registerPublicProperties';
import { CommonConfig } from '../entry/visitorContextConfig/types';
import get from '../entry/publicMethods/get/get';
import { getPlatform } from '../entry/buildUrl/getPlatformDomain';
import retrier from '../shared/utils/retrier';

const RETRIES = 5;

export const registerSetVisitorGdprConsent = (
  sid: number,
  vuid: string,
  sendDataEvent: SendDataEventFn,
  commonConfig: CommonConfig,
): void => {
  const setVisitorGdprConsent = (consent: StringOrBoolConsent) => {
    const expressedConsent = getExpressedConsentFromStringOrBoolean(consent);
    const previousConsent = get('visitor:GDPRConsent') as ExpressedConsent;

    if (expressedConsent === null || expressedConsent === previousConsent) {
      return;
    }

    const { engagement } = commonConfig;
    const platform = getPlatform();

    retrier<Response>(
      () =>
        fetch(
          `${engagement.url}/visitor-consents/ONSITE/${platform}-${sid}/${vuid}?actionId=00000000-0000-0000-0000-000000000000&platform=${platform}`,
          { method: 'PUT', body: String(expressedConsent) },
        ),
      RETRIES,
      (retriesLeft) => 1000 * (RETRIES + 1 - retriesLeft),
    );

    sendDataEvent({
      event: expressedConsent
        ? 'engagement.visitor.gdpr.gdprConsentAccepted'
        : 'engagement.visitor.gdpr.gdprConsentRefused',
      properties: { consentSource: 'WebSDK' },
    });
    getPublicPropertiesStore().dispatch(
      'visitor:GDPRConsent',
      expressedConsent,
    );
  };

  registerSetter('visitor:GDPRConsent', debounce(setVisitorGdprConsent, 500));
};
