import { getJSCustomData } from '../../../../entry/visitorContextConfig/getJSCustomData';
import {
  CommonConfig,
  CustomDataValues,
} from '../../../../entry/visitorContextConfig/types';
import { PublicPropertiesStore } from '../../../../shared/types/publicMethods';
import createState from '../../../../shared/utils/createState';
import { TriggerTargetingFn } from '../../../types';
import { getCustomDataDifference } from '../utils';

let timeout: number | null;

const [getInMemoryCustomData, setInMemoryCustomData] =
  createState<CustomDataValues>({});

function JSCustomDataObserver(
  sid: number,
  triggerTargeting: TriggerTargetingFn,
  isAuthenticationEnabled: boolean,
  commonConfig: CommonConfig,
): void {
  const JSCustomData = getJSCustomData();
  const inMemoryCustomData = getInMemoryCustomData();
  const updatedCustomData = getCustomDataDifference(
    inMemoryCustomData as Record<string, string>,
    JSCustomData as Record<string, string>,
  );

  if (Object.keys(updatedCustomData).length > 0) {
    setInMemoryCustomData({ ...inMemoryCustomData, ...updatedCustomData });
    triggerTargeting({
      registerNavigation: false,
      sid,
      customData: updatedCustomData,
      isAuthenticationEnabled,
      commonConfig,
    });
  } else {
    timeout = window.setTimeout(
      () =>
        JSCustomDataObserver(
          sid,
          triggerTargeting,
          isAuthenticationEnabled,
          commonConfig,
        ),
      1000,
    );
  }
}
export default function startJSCustomDataObserver(
  sid: number,
  triggerTargeting: TriggerTargetingFn,
  publicPropertiesStore: PublicPropertiesStore,
  isAuthenticationEnabled: boolean,
  commonConfig: CommonConfig,
  initialJSCustomData: Record<string, string | number | boolean>,
): void {
  setInMemoryCustomData(initialJSCustomData || {});
  JSCustomDataObserver(
    sid,
    triggerTargeting,
    isAuthenticationEnabled,
    commonConfig,
  );
  publicPropertiesStore.on('tag:version', (tagVersion) => {
    if (tagVersion === 'FULL') {
      clearTimeout(timeout!);
    }
  });
}
