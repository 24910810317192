import { getPublicPropertiesStore } from '../entry/publicMethods/publicProperties/registerPublicProperties';
import { MatchedEngagementRule } from '../entry/visitorContextConfig/types';
import {
  getExecutedEngagementRules,
  onExecutedEngagementRulesChange,
} from '../entry/visitorState/executedEngagementRules';
import { PublicPropertiesStore } from '../shared/types/publicMethods';
import diffArrays from '../shared/utils/diffArrays';
import { SendDataEventFn } from './types';

export default function trackEngagementRules(
  sendDataEvent: SendDataEventFn,
  publicPropertyStore: PublicPropertiesStore = getPublicPropertiesStore(),
) {
  const rulesChangedCallback = (
    executedEngagementRules: MatchedEngagementRule[],
    previouslyExecutedEngagementRules: MatchedEngagementRule[],
  ) => {
    const [, newlyTriggeredRules] = diffArrays(
      previouslyExecutedEngagementRules,
      executedEngagementRules,
    );

    const triggeredRules: MatchedEngagementRule[] = [];

    newlyTriggeredRules.forEach((engagementRule) => {
      const hasAvailability = engagementRule.availableChannels.length !== 0;

      sendDataEvent({
        event: 'engagement.rule.executed',
        properties: { engagementRuleId: engagementRule.engagementRuleId },
      });

      if (engagementRule.capped) {
        sendDataEvent({
          event: 'engagement.rule.displayCapped',
          properties: { engagementRuleId: engagementRule.engagementRuleId },
        });
      }

      if (!hasAvailability) {
        sendDataEvent({
          event: 'engagement.rule.displayMissed',
          properties: { engagementRuleId: engagementRule.engagementRuleId },
        });
      } else {
        window.iAdvizeCallbacks?.onTargetingRuleWishToDisplay?.(
          {
            id: engagementRule.engagementRuleId,
          },
          publicPropertyStore.getState()['visitor:sourceId']!,
          !engagementRule.authorized,
        );
      }

      publicPropertyStore.dispatch(
        'engagementRule:triggered',
        engagementRule.engagementRuleId,
      );
      triggeredRules.push(engagementRule);

      window.iAdvizeCallbacks?.onTargetingRuleTriggered?.({
        id: engagementRule.engagementRuleId,
      });
    });

    if (triggeredRules.length > 0) {
      publicPropertyStore.dispatch(
        'engagementRules:triggered',
        triggeredRules.map((rule) => rule.engagementRuleId),
      );
    }
  };

  //________________________
  rulesChangedCallback(getExecutedEngagementRules(), []);

  //________________________
  onExecutedEngagementRulesChange(rulesChangedCallback);

  //__________________________________________________________________________
  publicPropertyStore.on('tag:version', (tagVersion) => {
    if (tagVersion === 'FULL') {
      onExecutedEngagementRulesChange.clear(rulesChangedCallback);
    }
  });
}
