import { getStringConstantFromExpressedConsent } from '../shared/consentHelpers';
import { getLanguage } from '../entry/tagConfig';
import noop from '../shared/utils/noop';
import { getCookieConsent } from './cookieConsent';
import {
  DataEvent,
  DataEventCommonProperties,
  DataEventWithProperties,
  SendDataEventFn,
} from './types';
import { CommonConfig } from '../entry/visitorContextConfig/types';
import { getPlatform } from '../entry/buildUrl/getPlatformDomain';

export const launchDataPipeline = (
  vuid: string,
  sid: number,
  deviceId: string,
  commonConfig: CommonConfig,
) => {
  const getEnrichedEvent = (dataEvent: DataEvent, platform: string) => {
    const { event, properties: eventProperties = {} } =
      dataEvent as DataEventWithProperties;
    const commonProperties: DataEventCommonProperties = {
      cookieConsent:
        getStringConstantFromExpressedConsent(getCookieConsent()) || 'unknown',
      deviceId,
      projectId: `${platform}-${sid}`,
      sourceVisitorId: vuid,
      url: window.location.href,
      userAgent: navigator.userAgent,
      visitorDate: new Date().toISOString(),
      visitorLanguage: getLanguage() || navigator.language.slice(0, 2),
    };
    return { event, properties: { ...commonProperties, ...eventProperties } };
  };

  const sendDataEvent: SendDataEventFn = (dataEvent: DataEvent) => {
    //____________________________________________
    if (!getCookieConsent()) {
      return;
    }

    const body = JSON.stringify(getEnrichedEvent(dataEvent, getPlatform()));
    fetch(commonConfig.eventsCollector.url, {
      method: 'POST',
      body,
      headers: { 'Content-Type': 'application/json' },
    })
      //________________________________
      .catch(noop);
  };

  return {
    sendDataEvent,
  };
};
