import { registerPublicMethods } from '../../iAdvizeInterface/iAdvizeInterface';
import { browsingTimeSpentOnPageTimer } from '../../visitorState/browsingTimeSpentOnPageTimer';
import { activeTimeSpentOnPageTimer } from '../../visitorState/visitorActivityOnPage';

export default (
  launchdarklyClientKey: string,
  graphQLUrl: string,
  engagementApiUrl: string,
  language: string,
) =>
  import(
    /*_________________________________________*/ '../../../debug/launcher'
  ).then((m) => {
    const debugToolSession = sessionStorage.getItem('idz-debugTool');
    const debug = () => {
      m.default(
        launchdarklyClientKey,
        graphQLUrl,
        engagementApiUrl,
        language,
        browsingTimeSpentOnPageTimer,
        activeTimeSpentOnPageTimer,
      );
    };
    registerPublicMethods({ debug });

    if (debugToolSession) {
      debug();
    }
  });
