const retrier = async <Res>(
  fn: () => Promise<Res>,
  retries = 3,
  timeout: number | ((retriesLeft: number) => number) = 200,
): Promise<Res> => {
  try {
    const res = await fn();
    return res;
  } catch (err) {
    if (retries === 0) {
      throw err;
    }
    return new Promise((resolve) => {
      const retry = () => {
        const computedTimeout =
          typeof timeout === 'number' ? timeout : timeout(retries);
        setTimeout(() => {
          resolve(retrier(fn, retries - 1, timeout));
        }, computedTimeout);
      };

      if (navigator.onLine) {
        retry();
      } else {
        window.addEventListener('online', retry, { once: true });
      }
    });
  }
};

export const intervalRetrier = (
  intervalPeriod: number,
  retries: number,
  callback: (isLastAttempt?: boolean) => boolean,
) => {
  let attempts = 0;

  const interval = setInterval(() => {
    attempts += 1;

    const isLastAttempt = attempts >= retries;
    const result = callback(isLastAttempt);

    if (result || attempts >= retries) {
      clearInterval(interval);
    }
  }, intervalPeriod);
};

export default retrier;
