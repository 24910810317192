import {
  ExpressedConsent,
  StringConsent,
  StringOrBoolConsent,
} from './types/consent';

const STRING_CONSENT_MAP = { true: true, false: false } as const;

export const getExpressedConsentFromStringOrBoolean = (
  consent: StringOrBoolConsent,
): ExpressedConsent => {
  if (consent === null) {
    return null;
  }
  return typeof consent === 'boolean'
    ? consent
    : STRING_CONSENT_MAP[consent] ?? null;
};

export const getStringConstantFromExpressedConsent = (
  expressedConsent: ExpressedConsent,
): StringConsent =>
  typeof expressedConsent === 'boolean'
    ? (String(expressedConsent) as StringConsent)
    : null;
