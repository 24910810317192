import getTopDomain from '../storage/cookieStorage/getTopDomain';
import { getCookie } from '../storage/cookieStorage/getCookie';
import { delCookie } from '../storage/cookieStorage/delCookie';
import { DEFAULT_TTL_S } from '../storage/cookieStorage/constants';
import { setCookie } from '../storage/cookieStorage/setCookie';
import { StorageType } from '../shared/types/storageType';
import { ExpressedConsent } from '../shared/types/consent';
import publicGet from './publicMethods/get/get';

let storageType: StorageType | null = null;

const getIsCookieStorageAvailable = (): boolean => {
  const isCookieStorageAvailable =
    (publicGet('visitor:cookiesConsent') as ExpressedConsent) === true;
  storageType = isCookieStorageAvailable
    ? 'FIRST_PARTY'
    : 'FIRST_PARTY_SESSION_FALLBACK';
  return isCookieStorageAvailable;
};

export const getStorageType = (): StorageType => {
  if (!storageType) {
    getIsCookieStorageAvailable();
  }
  return storageType!;
};

/*_________________________________________________________________________________________________*/
const del = (key: string): void => {
  if (!getIsCookieStorageAvailable()) {
    sessionStorage.removeItem(key);
    return;
  }
  delCookie(key);
};

const get = (key: string): string | null => {
  if (!getIsCookieStorageAvailable()) {
    return sessionStorage.getItem(key);
  }
  return getCookie(key);
};

const set = (
  key: string,
  value: string,
  options: { ttl: number } = { ttl: DEFAULT_TTL_S },
): void => {
  if (!getIsCookieStorageAvailable()) {
    sessionStorage.setItem(key, value);
    return;
  }
  setCookie({
    domain: getTopDomain(),
    name: key,
    value,
    ttl: options.ttl,
  });
};

export default {
  del,
  get,
  set,
};
